export default [
  {
    title: 'Dashboards',
    route: 'dashboards',
    icon: 'HomeIcon',
  },
  {
    title: 'SmartIdData',
    route: 'smartIdData',
    icon: 'UserIcon',
  },
]
